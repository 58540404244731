.courseContentWrapper {
  background: #161616;
  width: 100%;
  text-align: center;
}

.courseContent__gridWrapper {
  border: 1px solid white;
  padding: 30px;
  background: transparent;
  color: var(--chakra-colors-brand-white);
  margin-top: 40px;
}

.courseContent__grid {
  grid-template-columns: repeat(1, 1fr);
  column-gap: 35px;
  row-gap: 30px;
}

.courseContentWrapper p {
  color: white;
}

@media only screen and (min-width: 769px) {
  .courseContent__grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 35px;
    row-gap: 30px;
  }
}

@media only screen and (min-width: 1025px) {
  .courseContent__grid {
    column-gap: 75px;
  }

  .courseContent__gridWrapper {
    padding: 50px;
  }
}
